import React, { ReactNode } from 'react';
import theme from './shared/themes/app.theme';
import { ThemeProvider } from '@emotion/react';
import { UIActionsProvider } from './contexts/ui-actions/ui-actions.context';
import { DrawerProvider } from './contexts/drawer/drawer.context';

interface AppProviders {
    children: ReactNode;
}

export const AppProviders = ({ children }: AppProviders): JSX.Element => (
    <ThemeProvider theme={theme}>
        <UIActionsProvider>
            <DrawerProvider>{children}</DrawerProvider>
        </UIActionsProvider>
    </ThemeProvider>
);
